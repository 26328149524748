import corruption from 'assets/images/footer/corruption.png'
import gupr from 'assets/images/footer/gupr.jpg'
import minskGov from 'assets/images/footer/minsk-gov.png'
import pravo from 'assets/images/footer/pravoby.png'
import presidentSite from 'assets/images/footer/president-site.png'
import year2025 from 'assets/images/footer/year2025.jpg'

export const social = [
	{ icon: 'vk', link: 'https://www.vk.com/gummnsk' },
	{
		icon: 'fb',
		link: 'https://www.facebook.com/Gum-official-109364484595078',
	},
	{ icon: 'ok', link: 'https://ok.ru/profile/565910890490' },
	{ icon: 'instagram', link: 'https://www.instagram.com/gum_official' },
]

export const otherLinks = [
	{ to: '/anti-corruption', icon: corruption, internal: true },
	{ to: 'http://president.gov.by/', icon: presidentSite, internal: false },
	{ to: 'https://pravo.by/', icon: pravo, internal: false },
	{ to: 'https://stu.by', icon: gupr, internal: false },
	{ to: 'https://president.gov.by/ru/documents/ukaz-no-1-ot-3-anvara-2025-g', icon: year2025, internal: false },
	{ to: 'https://minsk.gov.by/ru/', icon: minskGov, internal: false },
]

export const footerLink = 'https://gum.by/'

export const navBlocks = {
	'ru-RU': [
		{
			id: 1, title: 'Каталог', navLinks: [
				{ to: '/products/decor-textile/ht-linens', title: 'Постельное белье' },
				{ to: '/products/dishes-and-accessories/cu-panful', title: 'Сковороды' },
				{ to: '/products/decor-textile/d-wall-clock-table', title: 'Часы настенные, настольные' },
				{ to: '/products/beauty-and-style/bsa-bags-backpacks', title: 'Сумки, рюкзаки' },
				{ to: '/products/beauty-and-style/cpa-face-care', title: 'Уход за лицом' },
			],
		},
		{
			id: 2, title: 'Услуги', navLinks: [
				{ to: '/buyers/b-shipping', title: 'Доставка товаров' },
				{ to: '/free-wi-fi', title: 'Бесплатный WI-FI' },
				{ to: '/buyers/b-services/bankingServices', title: 'Услуги банков' },
				{ to: '/buyers/b-services/personalServices', title: 'Бытовые услуги' },
				{ to: '/buyers/b-services/partnerServices', title: 'Предложения партнеров' },
				{ to: '/buyers/b-services/storesServices', title: 'Услуги в магазинах "Щедрый"' },
			],
		},
		{
			id: 3, title: 'Акции', navLinks: [
				{ to: '/stocks/about', title: 'Общие правила' },
				{ to: '/stocks/s-in-the-hum/regular', title: 'Главные акции' },
				{ to: '/stocks/s-in-the-hum/sale', title: 'Распродажи' },
				{ to: '/for-business/fb-shares', title: 'Бизнесу' },
				{ to: '/stocks/s-in-the-hum/gifts', title: 'Подарки' },
			],
		},
		{
			id: 4, title: 'Помощь', navLinks: [
				{ to: '/kak-zakazat', title: 'Как заказать товар' },
				{ to: '/exchange-and-refund', title: 'Обмен и возврат товара' },
				{ to: '/buyers/b-gift-certificate', title: 'Подарочный сертификат' },
				{ to: '/tax-free', title: 'Tax-free' },
				{ to: '/contacts', title: 'Контакты' },
				{ to: '/sitemap', title: 'Карта сайта' },
				{ to: '/regulations', title: 'Нормативные документы' },
				{ to: '/buyers/privacy-policy', title: 'Политика конфиденциальности' },
			],
		},
	],
	be: [
		{
			id: 1, title: 'Аб кампаніі', navLinks: [
				{ to: '/by/about-company/ac-leadership', title: 'Кіраўніцтва' },
				{ to: '/by/about-company/commercial-network', title: 'Гандлёвая сетка' },
				{ to: '/about-company/ac-shareholders', title: 'Акцыянерам' },
				{ to: '/about-company/ac-our-life', title: 'Наша жыццё' },
				{ to: '/by/about-company/ac-history', title: 'Гісторыя' },
				{ to: '/by/about-company/ac-vacancies', title: 'Вакансіі' },
			],
		},
		{
			id: 2, title: 'Зварот', navLinks: [
				{ to: '/by/appeals/electronicae-appellationes', title: 'Электронныя звароты' },
				{ to: '/by/appeals/a-personal-reception', title: 'Асабісты прыём грамадзян і юр.асоб' },
				{
					to: '/by/appeals/a-list-of-administrative-procedures',
					title: 'Пералік адміністрацыйных працэдур'
				},
				{ to: '/by/appeals/a-book-of-comments-and-suggestions', title: 'Кніга заўваг і прапаноў' },
			],
		},
		{
			id: 3, title: 'Кантакты', navLinks: [
				{ to: '/by/contacts/сon-requisites', title: 'Рэквізіты' },
				{ to: '/by/contacts/con-departments-and-services', title: 'Аддзелы і службы' },
			],
		},
	],
}
